import React, { Component } from "react";

class AssessmentLayout extends Component {
    render() {
        return (
            <main className="bg-gray-100">
                {this.props.children}
            </main>
        );
    }
}

export default AssessmentLayout;