import React, { useState } from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import { withFirebase } from 'components/Firebase';

const EmailVerification = (props) => {

    const {user} = props;
    const [isSent, setIsSent] = useState(false);

    const needsEmailVerification = authUser =>
        authUser &&
        !authUser.emailVerified &&
        authUser.providerData
            .map(provider => provider.providerId)
            .includes('password');


    const onSendEmailVerification = () => {
        props.firebase
            .doSendEmailVerification()
            .then(() => setIsSent(true));
    };


    if (needsEmailVerification(user)) {
        return (

            <div className="mb-8">
                <div className="flex flex-col ">
                    <div className="alert bg-gray-200">
                        <div className="flex-1">
                            <HiOutlineMail className="flex-shrink-0 w-6 h-6 mx-2" />
                            {isSent ? (
                                <label className="mx-3">
                                    <h4>
                                        E-Mail confirmation sent
                                    </h4>
                                    <p className="text-sm text-content-400">Check you E-Mails (Spam
                                    folder included) for a confirmation E-Mail.
                                    Refresh this page once you confirmed your E-Mail.
                            </p> </label>

                            ) : (
                                <label className="mx-3">
                                    <h4>
                                        Please verify your email
                                    </h4>
                                    <p className="text-sm text-content-400">
                                        Check you E-Mails (Spam folder
                                        included) for a confirmation E-Mail or click the button to send
                                        another confirmation E-Mail.
                                    </p>
                                </label>
                            )}
                        </div>
                        <div className="flex-none">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={onSendEmailVerification}
                                disabled={isSent}
                            >
                                Send confirmation E-Mail
                                        </button>
                        </div>
                    </div>
                </div>
            </div>
        );

    } else {
        return null;
    }
}

export default withFirebase(EmailVerification);