import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { HiOutlineArrowRight, HiOutlineChat, HiOutlineCheckCircle, HiOutlineStar, HiStar } from 'react-icons/hi'

import { withFirebase } from 'components/Firebase';
import { useModal } from 'components/Modal';
/**
 * Feedback button
 * 
 * Used to collect feeback from users. When the button is pressed, we render a fancy feedback form 
 * with a thank you message. Works like magic :)
 * 
 */






/**
 * Star rating
 * 
 * Renders five star rating component, and returns the rating to the parent object 
 * via the handleRating function
 * 
 * @param {handleRating} props - a function to receive the rating  
 * @returns 
 */
const StarRating = (props) => {

    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const { handleRating } = props;

    const handleSetRating = (index) => {
        handleRating(index);
        setRating(index);
    }

    return (
        <div>{props.children}
            <div className="star-rating flex flex-row">
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (

                        <button
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "fill-current text-yellow-600" : "fill-current text-gray-600"}
                            onClick={() => handleSetRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            {rating >= index ? (
                                <span className="star"><HiStar size={36} /></span>
                            ) : (
                                <span className="star"><HiOutlineStar size={36} /></span>
                            )}

                        </button>

                    );
                })}
            </div>
        </div>
    );
};

/**
 * FeedbackFrom
 * 
 * Used to collect user feedback and suggestions
 */
const FeedbackFormBase = (props) => {
    const { onCancel, user } = props;;

    const INIT_FEEDBACK = { type: '', details: '', rating: false, anonymous: false, url: '' }

    const [feedback, setFeedback] = useState(INIT_FEEDBACK)
    const [sent, setSent] = useState(false);

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        // Handle make the feedback anonymous if so requested

        if (feedback.anonymous) {
            feedback.user = '';
        } else {
            feedback.user = {
                email: user.email,
                id: user.id,
                status: user.status,
                teams: user.teams,
                
            }
        }

        // We want to know what page the user is looking at
        feedback.url =  props.location.pathname;
        feedback.createdOn = new Date();
        
        
        await props.firebase.feedbacks().add(feedback);
        setFeedback(INIT_FEEDBACK);
        setSent(true);
    }

    // When the user selects different rating, we want to update the state
    const handleSetRating = (rating) => {
        setFeedback((prev) => {
            return ({
                ...prev,
                'rating': rating,
            })
        });
    }

    // When we change any of the form's inputs
    const handleUpdateField = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFeedback((prev) => {
            return ({
                ...prev,
                [name]: value
            })
        });
    }


    // We render the form if not submitted, else we render the thank you screen
    return (
        <div className="transition ease-in-out">
        {sent ? (
            <div className="w-full h-full flex flex-col space-y-6 items-center">
                <HiOutlineCheckCircle size={64} className="stroke-current text-primary" />
               <h2 className="text-2xl">Thank you!</h2>
               <p>We appreciate your feedback. We'll review it and act on it shortly.</p>
               
               <div className="space-x-2 justify-right card-actions">
                    <button className="btn btn-primary" onClick={onCancel}>Continue <HiOutlineArrowRight size={18} className="ml-1" /> </button>
                </div>
            </div>
         ):(
        <div className="bg-white w-full">
            <div className="flex flex-row items-center mb-4">
                <HiOutlineChat size={24} className="mr-1" />
                <h2 className="text-2xl flex flex-row "> Feedback</h2>
            </div>
            <p className="mb-8">We are always looking for ways to improve Agimo. We appreciate your input!</p>

            <form
                className="flex flex-col w-full space-y-8"
                onSubmit={(e) => handleOnSubmit(e)}>

                <div className="form-control">
                    <label htmlFor="type">
                        <span className="label-text">Regarding</span>
                    </label>
                    <select
                        className="select select-bordered"
                        id="type"
                        name="type"
                        value={feedback.type}
                        onChange={handleUpdateField}
                    >
                        <option disabled>Choose type of feedback</option>
                        <option>Feature enhancement</option>
                        <option>Bug or a problem</option>
                        <option>Other</option>

                    </select>
                </div>

                <div className="form-control">
                    <label htmlFor="details">
                        <span className="label-text">Suggestion</span></label>
                    <textarea
                        className="textarea textarea-bordered  h-64"
                        placeholder=""
                        id="details"
                        name="details"
                        type="text"
                        value={feedback.details}
                        onChange={handleUpdateField} />
                    <div>
                    </div>


                    <div className="mt-4">
                        <StarRating handleRating={handleSetRating}>
                            <span className="label-text">Rate how it is right now</span>
                        </StarRating>
                    </div>

                    <div className="form-control flex flex-row items-center mt-4">
                        <label className="cursor-pointer label" >
                            <span className="label-text">Anonymous feedback (not connected to your account)</span>
                       
                        <div className="ml-2">
                            <input  
                                name="anonymous" 
                                type="checkbox" 
                                checked={feedback.anonymous} 
                                onChange={handleUpdateField} 
                                className="checkbox" />
                            <span className="checkbox-mark"></span>
                            </div> 
                        </label>
                    </div>

                    <div className="space-x-2 justify-right card-actions">
                        <button className="btn btn-primary" type="submit">Give Feedback</button>
                        <button className="btn btn-ghost" onClick={onCancel}>Cancel</button>
                    </div>

                </div>
            </form>
        </div>
        )}
        </div>
    )
}
const FeedbackForm = withRouter(withFirebase(FeedbackFormBase));


/**
 * Feedback button
 *
 * Shows the feedback form as a modal. This is the start to everything
 *
 * @param { } props
 * @returns
 */

const FeedbackButton = (props) => {

    const { setModal, unSetModal } = useModal()
    const {user} = props;

    return (
        <span onClick={() => setModal(<FeedbackForm onCancel={unSetModal} user={user} />)}  {...props}>
            {props.children}
        </span>
    )
}

export default FeedbackButton;