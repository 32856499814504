import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from 'components/App/logo';

import {
    HiOutlineCog,
    HiOutlineHome,
    HiOutlineUsers,
    HiOutlineCollection,
    HiOutlineDocumentDuplicate,
    HiOutlineClipboardList,
    HiOutlineSpeakerphone
} from "react-icons/hi";

import * as ROUTES from 'constants/routes';
import FeedbackButton from 'components/Feedback/FeedbackButton';


const Sidebar = (props) => {

    const { user } = props;

    const getUserName = (user) => {
        if (user) {
            return user.username || user.email.split('@')[0];
        } else {
            return '';
        }
    }

    // Should we show the teams tab?
    const showTeams =  props.user && props.user.teams && Object.keys(props.user.teams).length > 1; // If user only belongs to one team, we hide the team list tab

    // Should we show the results tab?
    const showResults =  props.user && props.user.results ? true : false;

    const navItem = (to, text, icon) => (
        <li className="h-full lg:w-16 xl:w-60  lg:ml-4 content-center pt-2 xl:justify-items-start">
            <NavLink data-testid={'navlink-dashboard'}
                to={to}
                className="box-border overflow-hidden h-full flex flex-col xl:flex-row lg:w-20 xl:w-60 xl:mr-10 xl:pl-4 place-content-center xl:place-content-start border-b-8 border-transparent mr-1 lg:border-none lg:p-3 xl:ml-10"
                activeClassName="box-border border-white text-white lg:bg-white lg:text-gray-800 lg:rounded-tl-2xl lg:rounded-bl-2xl">
                {React.createElement(icon, {
                    'className': "inline-block w-5 h-5 sm:w-6 sm:h-6 stroke-current",
                    'aria-hidden': "true"
                })}

                <span className="text-lg hidden xl:inline ml-2">
                    {text}
                </span>
            </NavLink>
        </li>
    )


    return (
        <div id="sidebar" className="flex flex-row lg:flex-col justify-between bg-agimo-purple text-white border-content-200  w-full h-16 lg:h-full lg:top-0 rounded-2xl">

            {/* Top level nav items */}
            <div className="xl:overflow-y-hide flex flex-row justify-start lg:flex-col w-full">

                <div className="lg:mx-auto">
                    <div className="p-4 pl-6 lg:p-3 lg:pl-0 lg:pt-6 xl:p-14  xl:mx-0">
                        <Logo />
                    </div>
                </div>

                <ul className="inline-grid w-full grid-cols-5 gap-2 sm:gap-4 lg:grid-cols-1 lg:gap-6 items-center xl:place-items-start justify-items-center " >
                    { navItem(ROUTES.DASHBOARD, 'Dashboard', HiOutlineHome)}
                    { showTeams &&  navItem(ROUTES.TEAMS, 'Teams', HiOutlineUsers)}
                    { navItem(ROUTES.ASSESSMENTS, 'Assessments', HiOutlineCollection)}
                    { showResults &&  navItem(ROUTES.RESULTS, 'Results', HiOutlineDocumentDuplicate)}
                    { navItem(ROUTES.TASKS, 'Tasks', HiOutlineClipboardList)}
                </ul>
            </div>

            <div className="xl:overflow-y-hide  flex flex-row lg:flex-col lg:ml-2 lg:pb-4 ">

                <ul className="grid grid-cols-2 mr-4 lg:mr-0 lg:grid-cols-1 gap-6 sm:gap-10 lg:gap-12 lg:flex-col lg:mb-4 items-center xl:place-items-start justify-items-center">
                    {/* User profile part */}

                    <li className="h-full lg:w-16 xl:w-60  lg:ml-4 content-center pt-2 xl:justify-items-start">
                        <FeedbackButton user={user}className="box-border overflow-hidden h-full flex flex-col xl:flex-row lg:w-20 xl:w-60 xl:mr-10 xl:pl-4 place-content-center xl:place-content-start border-b-8 border-transparent mr-1 lg:border-none lg:p-3 xl:ml-10"
                >
                            <HiOutlineSpeakerphone size={24} className="inline-block  w-5 h-5 lg:mr-2 stroke-current" />
                            <span className="text-lg hidden xl:inline">Feedback</span>
                        </FeedbackButton>
                    </li>

                    { navItem(ROUTES.ACCOUNT,  getUserName(user) || 'Settings', HiOutlineCog)}

                </ul>
            </div>
        </div>
    )
}

export default Sidebar