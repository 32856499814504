// Landing page routes
export const LANDING = '/';

// Authentication routes
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';

// Routes for logged in users
export const DASHBOARD = '/dashboard';
export const TEAMS = '/teams';
export const TEAMS_DETAILS = '/teams/:id';
export const TASKS = '/tasks';
export const ASSESSMENTS = '/assessments';
export const ASSESSMENT_DETAILS = '/assessments/:id';

export const RESULTS = '/results';
export const RESULTS_DETAILS = '/results/:id';

export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';

export const TERMS_OF_SERVICE = '/legal/terms';
export const PRIVACY_POLICY = '/legal/privacy'