import React, { Fragment, useState } from "react";
import { NavLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { AuthUserContext } from 'components/Session';
import { withFirebase } from 'components/Firebase';
import Logo from 'components/App/logo';


import * as ROUTES from 'constants/routes';
import {
    HiOutlineUser,
} from 'react-icons/hi';

import {
    HiOutlineMenu,
    HiOutlineX
} from 'react-icons/hi';

function NavbarBase(props) {
    // Get auth state and re-render anytime it changes
    const [show, setShow] = useState(false);
    const [beTransparent, setBeTransparent] = useState(true);


    const navItem = (title, location) => (
        <div className="box-content pr-2 pl-2 lg:pr-4 lg:pl-4">
            <ScrollLink
                to={location}
                spy={true}
                smooth={true}
                duration={250}
                activeClass="text-neutral-focus"
                className="text-xl font-semibold text-gray-500" >
                {title}
            </ScrollLink>
        </div>
    )

    const toggleShow = () => {
        setShow(!show)
    }

    return (

        <AuthUserContext.Consumer>
            {authUser => (
                <div id="navbar" className={`${beTransparent ? 'bg-transparent' : 'bg-white bg-opacity-90'} fixed w-full z-10 t-0 transition duration-300 ease-in-out `}>

                    <div className="items-center my-4 ">

                        <div className="relative px-8">
                            <nav className="relative flex items-center justify-between w-full" aria-label="Global">
                                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                    <div className="flex items-center justify-between w-full md:w-auto">
                                        <div className="flex place-items-center">
                                            <ScrollLink
                                                to={"banner"}
                                                spy={true}
                                                onSetActive={() => setBeTransparent(true)}
                                                onSetInactive={() => setBeTransparent(false)}
                                                smooth={true}
                                                duration={250}>
                                                <Logo background={beTransparent ? 'light' : 'dark'} />
                                            </ScrollLink>
                                        </div>
                                        <div className="-mr-2 flex items-center md:hidden">
                                            <button onClick={toggleShow} type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
                                                <span className="sr-only">Open main menu</span>
                                                <HiOutlineMenu size={24} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex ml-4 sm:ml-8 lg:ml-16 flex-row md:w-full h-full items-center justify-between">
                                    <div className="hidden md:flex flex-row h-full items-center ">
                                        {navItem("Product", "product")}
                                        {navItem("Features", "features")}
                                        {navItem("Company", "company")}
                                    </div>

                                    <div className="hidden r-0 md:block md:ml-10 md:pr-4 md:space-x-8 text-xl">
                                        {authUser ? (
                                            <Fragment>
                                                <div className="inline-block align-middle text-sm">
                                                    <NavLink to={ROUTES.DASHBOARD}>
                                                        <div className="inline-block align-middle text-base text-gray-500 hover:text-indigo-500">
                                                            <HiOutlineUser size={22} className="inline-block mr-1" />
                                                            {authUser.email}
                                                        </div>
                                                    </NavLink>
                                  
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <Fragment >
                                                <div className="inline-block align-middle text-gray-500">
                                                    <div className="min-w-128 w-128 space-x-4">
                                                        <NavLink className="btn btn-primary font-semibold" to={ROUTES.SIGN_UP}>
                                                            <span className="ml-2 mr-2 xs:ml-4 xs:mr-4 lg:ml-8 lg:mr-8">Sign up</span>
                                                        </NavLink>
                                                        <NavLink className="btn btn-secondary font-semibold" to={ROUTES.SIGN_IN}>
                                                            <span className="ml-2 mr-2  lg:ml-4 lg:mr-4">Login</span></NavLink>
                                                    </div>
                                                </div>
                                            </Fragment>

                                        )}

                                    </div>
                                </div>
                            </nav>
                        </div>

                        {/*<!--
                Mobile menu, show/hide based on menu open state.
        
                Entering: "duration-150 ease-out"
                  From: "opacity-0 scale-95"
                  To: "opacity-100 scale-100"
                Leaving: "duration-100 ease-in"
                  From: "opacity-100 scale-100"
                  To: "opacity-0 scale-95"
              -->*/}

                        {show && (
                            <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden ">
                                <div className="rounded-lg  shadow bg-white ring-1 ring-black ring-opacity-5 overflow-hidden divide-y-2 divide-gray-200">
                                    <div className="px-5 pt-4 flex mb-6 items-center justify-between">
                                        <div className="flex place-items-center  mt-4">
                                            <div className="">
                                                <h1 className="p-4 inline xl:hidden text-2xl font-mono">A</h1>
                                                <h1 className="p-2 hidden xl:inline text-4xl font-mono">Agimo</h1>
                                            </div>
                                        </div>
                                        <div className="mr-2">
                                            <button onClick={toggleShow} type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close main menu</span>
                                                <HiOutlineX size={24} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="px-2 pt-2 pb-3 space-y-1 ">
                                        <NavLink to="#product" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Product</NavLink>

                                        <NavLink to="#features" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Features</NavLink>

                                        <NavLink to="#product" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Company</NavLink>


                                        {authUser ? (
                                            <Fragment>
                                                <div className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">
                                                    <NavLink to={ROUTES.DASHBOARD}>
                                                        <div className="inline-block align-middle text-base text-gray-500 hover:text-indigo-500">
                                                            <HiOutlineUser size={22} className="inline-block mr-1" />
                                                            {authUser.email}
                                                        </div>
                                                    </NavLink>
                                                </div>

                                                
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <div className="space-x-2">
                                                    <NavLink className="btn btn-ghost btn-outline font-medium" to={ROUTES.SIGN_IN}>Login</NavLink>
                                                    <NavLink className="btn btn-primary btn-outline font-medium" to={ROUTES.SIGN_UP}>Sign up</NavLink>
                                                </div>
                                            </Fragment>

                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            )}
        </AuthUserContext.Consumer>

    );
}

const Navbar = withFirebase(NavbarBase);
export default Navbar;
