import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { withAuthentication } from 'components/Session';


/* External facing landing pages */
import Landing from 'pages/landing/Landing';


/* The layout section  */
import { AuthLayout, AppLayout, LandingLayout, AssessmentLayout, ArticleLayout } from 'layouts';

/* Basic functionality pages */
const Dashboard = lazy(() => import('pages/Dashboard'));
const Account = lazy(() => import('pages/Account'));
const Admin = lazy(() => import('pages/Admin'));

/* Maturity assessment specific pages */
const Catalog = lazy(() => import('pages/maturity/Catalog'));
const Results = lazy(() => import('pages/maturity/Results'));
const Assessment = lazy(() => import('pages/maturity/Assessment'));

/* General app pages  */
const Teams = lazy(() => import('pages/Teams'));
const Tasks = lazy(() => import('pages/Tasks'));

/* Legal pages */
const TermsOfService = lazy(() => import('pages/legal/Terms'));
const PrivacyPolicy = lazy(() => import('pages/legal/Privacy'));

/* Authentication pages */
const SignUp = lazy(() => import('pages/auth/SignUp'));
const SignIn = lazy(() => import('pages/auth/SignIn'));
const PasswordForget = lazy(() => import('pages/auth/PasswordForget'));


const NotFoundPage = () => (<div><h1 data-testid="heading">404</h1><p>Sorry, No page found</p></div>);

const App = (props) => (
  <div className="w-full h-full">

    <Switch>
      <Route exact path={[ROUTES.LANDING]}>
        <LandingLayout>
          <Switch>
            <Route exact path={ROUTES.LANDING} component={Landing} />
          </Switch>
        </LandingLayout>
      </Route>

      <Route exact path={[ROUTES.SIGN_UP, ROUTES.SIGN_IN, ROUTES.PASSWORD_FORGET]}>
        <AuthLayout>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>

              <Route path={ROUTES.SIGN_UP} component={SignUp} />
              <Route path={ROUTES.SIGN_IN} component={SignIn} />
              <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForget}
              />

            </Switch>
          </Suspense>
        </AuthLayout>
      </Route>

      <Route exact path={[ROUTES.DASHBOARD, ROUTES.TEAMS, ROUTES.TEAMS_DETAILS, ROUTES.TASKS,
      ROUTES.ASSESSMENTS, ROUTES.RESULTS, ROUTES.RESULTS_DETAILS, ROUTES.ACCOUNT, ROUTES.ADMIN]}>
        <AppLayout>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path={ROUTES.DASHBOARD} component={Dashboard} />
              <Route path={ROUTES.TEAMS} component={Teams} />
              <Route path={ROUTES.TEAMS_DETAILS} component={Teams} />
              <Route path={ROUTES.ASSESSMENTS} component={Catalog} />
              <Route path={ROUTES.RESULTS} component={Results} />
              <Route path={ROUTES.RESULTS_DETAILS} component={Results} />
              <Route path={ROUTES.TASKS} component={Tasks} />

              <Route path={ROUTES.ACCOUNT} component={Account} />
              <Route path={ROUTES.ADMIN} component={Admin} />
            </Switch>
          </Suspense>
        </AppLayout>
      </Route>

      <Route exact path={[ROUTES.ASSESSMENT_DETAILS]}>
        <AssessmentLayout>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path={ROUTES.ASSESSMENT_DETAILS} component={Assessment} />
            </Switch>
          </Suspense>
        </AssessmentLayout>
      </Route>

      <Route path={[ROUTES.TERMS_OF_SERVICE, ROUTES.PRIVACY_POLICY]}>
        <ArticleLayout>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path={ROUTES.TERMS_OF_SERVICE} component={TermsOfService} />
              <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
            </Switch>
          </Suspense>
        </ArticleLayout>
      </Route>

      <Route path="*" component={NotFoundPage} />
    </Switch>

  </div>
);

export default withAuthentication(App);