import React, { Component } from "react";
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import * as ROUTES from 'constants/routes';

/**
 * We'll use this to show Knowledge base articles on our website, etc.. 
 */
const relativeBlockStyle = {
    height: 500
}
class ArticleLayout extends Component {
    render() {
        return (
            <article className="h-full">
                <section className="relative block bg-gradient-to-br h-full w-full from-primary hover:to-green-100 w-full  shadow" style={relativeBlockStyle}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover">
                        <div className="text-white ml-4 md:ml-12 lg:ml-24 mt-2 sm:mt-12">

                            <Link to={ROUTES.SIGN_UP} className="bg-white z-10"> 
                                <div className="flex w-128 flex-row space-x-2 align-middle text-sm sm:text-base md:text-lg">
                                    <HiOutlineArrowLeft size={28}/>
                                    <p>Back to Agimo</p>
                                </div>
                            </Link>

                        </div>
                        <span className="bg-indigo-800 w-full h-full"></span>
                    </div>
                    <div className="bg-indigo-800"></div>
                </section>

                <div className="mx-auto w-full absolute top-12 p-4 sm:top-24 lg:top-36 xl:top-48 ">
                    <div className="bg-white max-w-5xl p-12 w-full relative mx-auto t-0 text-gray-700 shadow">
                        {this.props.children}
                    </div>
                    <div className="mx-auto w-full mt-12 grid justify-items-center">
                        <Link className="btn btn-primary" to={ROUTES.SIGN_UP}>Back to previous page</Link>
                    </div>
                </div>

            </article>

        )
    }
}

export default ArticleLayout;