import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {withFirebase} from 'components/Firebase';

/**
 * Component to track page views using firebase analytics
 * goes somewhere inside the <BrowserRouter > tag inside app
 * @param {} props 
 */

const AnalyticsComponentBase = (props) => {

    let location = useLocation();
    let user = props.firebase.auth.currentUser;

    useEffect(()=>{
        const pagePath = location.pathname + location.search;
        
        // Log the page view
        props.firebase.analytics.logEvent('page_view', {pagePath});

        // set the user id
        props.firebase.analytics.setUserId(user?.uid, {global: true});

    },[location, props, user]);

    // We don't render anything, just watch the location changes. just return null
    return null;
}

const AnalyticsComponent = withFirebase(AnalyticsComponentBase);
export default AnalyticsComponent
