import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import "firebase/performance";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  appId: process.env.REACT_APP_APP_ID

};

class Firebase {
  constructor() {

    app.initializeApp(config);

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    this.auth = app.auth();
    this.db = app.firestore();
    this.analytics = app.analytics()
    this.Timestamp = app.firestore.Timestamp;


// Initialize Performance Monitoring and get a reference to the service
   this.perf = app.performance();

    /* Social Sign In Method Provider */
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.microsoftProvider = new app.auth.OAuthProvider('microsoft.com');


    /* we want to connect to the emulator environment if we are developing locally */
    if (window.location.hostname === 'localhost') {
      this.db.useEmulator('localhost', 8080);
      this.auth.useEmulator('http://localhost:9099/', { disableWarnings: true });

    }

  }

  getProviderForProviderId = (id) => {
    switch (id) {
      case 'google.com':
        return this.googleProvider;
      case 'password':
        return this.emailAuthProvider;
      default:
        return false;
    }
  }

  existingAccountSignInWithNewProvider = (pendingCred, email) => {

    // Get the sign-in methods for this email.
    this.auth.fetchSignInMethodsForEmail(email).then(methods => {
      // If the user has several sign-in methods, the first method
      // in the list will be the "recommended" method to use.
      if (methods[0] === 'password') {
        // TODO: Ask the user for their password.
        // In real scenario, you should handle this asynchronously.
        var password = this.promptUserForPassword();
        this.auth.signInWithEmailAndPassword(email, password).then(result => {
          return result.user.linkWithCredential(pendingCred);
        }).then(() => {
          // Social account successfully linked to the existing user.

          return;
          //goToApp();
        });
        return;
      }


      // All other cases are external providers.
      // Construct provider object for that provider.
      // TODO: Implement getProviderForProviderId.
      var provider = this.getProviderForProviderId(methods[0]);
      // At this point, you should let the user know that they already have an
      // account with a different provider, and validate they want to sign in
      // with the new provider.
      // Note: Browsers usually block popups triggered asynchronously, so in
      // real app, you should ask the user to click on a "Continue" button
      // that will trigger signInWithPopup().
      this.auth.signInWithPopup(provider).then(result => {
        // Note: Identity Platform doesn't control the provider's sign-in
        // flow, so it's possible for the user to sign in with an account
        // with a different email from the first one.

        // Link the Facebook credential. We have access to the pending
        // credential, so we can directly call the link method.
        result.user.linkWithCredential(pendingCred).then(usercred => {
          // Success.
          return;
          //goToApp();
        });
      });
    });
  }

  // ** Analytics API */
  logEvent = (name, meta) => {
    this.analytics.logEvent(name, meta);
  }
  /** Utilities  */
  Timestamp = () => this.Timestamp;
  
  // *** Auth API ***

  promptUserForPassword = () => {
    return 'hello'
  };

  doCreateUserWithEmailAndPassword = (email, password, username, occupation, roles, callback = false) => {

    return this.auth
      .createUserWithEmailAndPassword(email, password)
      .then(authUser => {

        const userId = authUser.user.uid;

        // Create a user profile in your Firebase realtime database
        this
          .user(authUser.user.uid)
          .set({
            id: userId,
            username,
            email,
            occupation,
            roles,
            status: 'active'
          }, { merge: true })

          // Log it to analytics
          this.analytics.setUserId(authUser.uid);        
          this.analytics.logEvent('sign_up', {'method': 'Email'})  

        if(callback) {
          callback(true)}
        else {
          return authUser
        } ;
      }).catch(err => {
        throw err;
      });
  };

  doSignInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password).then(
      authUser => {

        // Log to analytics
        this.analytics.setUserId(authUser.uid);
        this.analytics.logEvent('login', {'method': 'Email'})
      }
    );
      
  }
    

  doSignInWithGoogle = (callback = false) => {
    return this.auth.signInWithPopup(this.googleProvider).then(socialAuthUser => {

      // Create a user in your Firebase Realtime Database too
      this.user(socialAuthUser.user.uid)
        .set({
          id: socialAuthUser.user.uid,
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
          status: 'active'
        }, { merge: true });

      // Send the signup or login to google analytics
      const event = socialAuthUser.additionalUserInfo.isNewUser ? 'sign_up' : 'login';
      this.analytics.logEvent([event], {'method': 'Google'})
      
      if(callback) {
        callback(true)}
      else {
        return socialAuthUser
      } ;
    }).catch(err => {
      // User's email already exists.
      if (err.code === 'auth/account-exists-with-different-credential') {
        this.existingAccountSignInWithNewProvider(err.credential, err.email);
      }
      return err;
    });
  }

  doSignInWithMicrosoft = (callback = false) => {
    return this.auth.signInWithPopup(this.microsoftProvider).then(socialAuthUser => {
      // Create a user in your Firebase Realtime Database too
      this.user(socialAuthUser.user.uid)
        .set({
          id: socialAuthUser.user.uid,
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
          status: 'active'
        }, { merge: true });

      // Send the signup or login to google analytics
      const event = socialAuthUser.additionalUserInfo.isNewUser ? 'sign_up' : 'login';
      this.analytics.logEvent([event], {'method': 'Microsoft'})
      
      if(callback) {
        callback(true)}
      else {
        return socialAuthUser
      } ;
    }).catch(err => {
      // User's email already exists.
      if (err.code === 'auth/account-exists-with-different-credential') {
        this.existingAccountSignInWithNewProvider(err.credential, err.email);
      }
      return err;
    });
  }

  doSignOut = () => this.auth.signOut().then(res => {
    this.analytics.logEvent('logout')
    sessionStorage.clear();
  });

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  doProfileUpdate = (name, photoUrl) => {
    this.auth.currentUser.updateProfile({
      displayName: name,
      photoUrl: photoUrl
    })
  }

  /**
   * Deletes the user account of the signed in user
   */
  doDeleteCurrentUserAccount = () => {
    this.auth.currentUser.delete();
  }

  doUpdateUserProfile = async (authUser, next) => {
    return this.user(authUser.uid)
      .onSnapshot(snapshot => {
        const dbUser = snapshot.data();

        // default empty roles
        if (dbUser && !dbUser.roles) {
          dbUser.roles = {}
        }

        // merge auth and db user
        authUser = {
          uid: authUser.uid,
          email: authUser.email,
          emailVerified: authUser.emailVerified,
          providerData: authUser.providerData,
          ...dbUser,
        };

        if (next) {
          next(authUser)
        } else {
          return authUser;
        };
      });
  }

  // Use this to refresh the user profile, for example after updating name or email, such.
  doReloadUser = async function () {
    let user = this.auth.currentUser;
    await user.reload();
    user = this.auth.currentUser;
    this.doUpdateUserProfile(user)
  }

  doChangeEmail = (email) => {
    this.auth.currentUser.updateEmail(email);
  }

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.doUpdateUserProfile(authUser, next)
      } else {
        fallback();
      }
    });

  /** firebase utils*/
  batch = () => this.db.batch();
  getTimestamp = () => this.fieldValue.serverTimestamp();

  // *** User API ***
  user = uid => this.db.doc(`users/${uid}`);
  users = () => this.db.collection('users');

  // *** Teams API ***
  team = uid => this.db.doc(`teams/${uid}`);
  teams = () => this.db.collection('teams');

  // ** Team memberships (users: many-to-many :teams) */
  membership = uid => this.db.doc(`memberships/${uid}`);
  memberships = () => this.db.collection('memberships')

  // *** Invite API ***
  invite = uid => this.db.doc(`invites/${uid}`);
  invites = () => this.db.collection('invites');

  // *** Tasks API ***
  task = uid => this.db.doc(`tasks/${uid}`);
  tasks = () => this.db.collection('tasks');

  // *** Assessment API ****
  // An assessment is the list of questions
  assessment = uid => this.db.doc(`assessments/${uid}`);
  assessments = () => this.db.collection('assessments');

  // The result is what the user fills out
  result = uid => this.db.doc(`results/${uid}`)
  results = () => this.db.collection('results')

  // Assessments are comprised out of a number of questions
  question = uid => this.db.doc(`questions/${uid}`);
  questions = () => this.db.collection('questions')

  // Feedback API used to collect user feedback
  feedback = uid => this.db.doc(`feedback/${uid}`);
  feedbacks = () => this.db.collection('feedbacks')

  // Emails API
  email = uid => this.db.doc(`emails/${uid}`);
  emails = () => this.db.collection('emails')

  // BetaSignup API - to be removed after beta signup
  betaSignup = uid => this.db.doc(`betaSignup/${uid}`);
  betaSignups = () => this.db.collection('betaSignup');
}

export default Firebase;