import React from 'react';

const Logo = (props) => {

    const textColor = props.background === 'light' ? 'text-white' : 'text-primary'

    return (
        <div className="flex place-items-center my-auto transition-gpu transition-all duration-300 ease-in-out">
            <div className="h-6 xl:h-12 w-1 xl:w-2 bg-secondary mr-2"></div>
            <div className={`${textColor} font-mono text-white tracking-wide font-extrabold`}  >
                <h1 className="inline  xl:hidden text-2xl font-mono">A</h1>
                <h1 className="hidden xl:inline text-4xl font-mono">Agimo</h1>
            </div>
        </div>
    )
}

export default Logo;