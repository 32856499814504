import React from 'react';
import { Link } from 'react-router-dom';
import { NewsletterSignup } from 'components/marketing/NewsletterSignup';
import {Helmet} from "react-helmet";

import * as ROUTES from 'constants/routes';
import { CgUserList, CgSmartphoneChip, CgBowl, CgCheckO, CgBoard, CgExtension, CgBulb } from 'react-icons/cg';
import { HiOutlineStar, HiOutlineClipboardCheck, HiOutlineFingerPrint, HiOutlineDocumentSearch, HiOutlineLightBulb, HiOutlineTrendingUp, HiOutlineBeaker, HiOutlineShieldCheck } from 'react-icons/hi';

/*
* Images
*/
import marketblast from './images/sammy-marketing.png';
import banner from './images/sammy-remote-work.png';

import product from './images/sammy-packages.png';
import manager from './images/sammy-chart.png';
import scrummaster from './images/sammy-plant-in-test-tube.png'
import developer from './images/sammy-browser-1.png';
import holistic from './images/sammy-come-back-later.png';


import screenshot2 from './images/screenshot.png';


const Cross = (props) => (
  <svg
    width={props.size + 10}
    height={props.size + 10}
    className={`${props?.transform && props.transform} transform-gpu opacity-50 text-${props.color} stroke-current`}
  >
    <line x1={props.size / 10} y1={props.size / 10} x2={props.size} y2={props.size} strokeLinecap="round" strokeWidth={props.size / 5} />
    <line x1={props.size} y1={props.size / 10} x2={props.size / 10} y2={props.size} strokeLinecap="round" strokeWidth={props.size / 5} />
  </svg>
)

const Squiggle = (props) => (
  <svg viewBox="0 0 200 100" width={props.size} height={props.size / 2} className={`${props?.transform && props.transform} transform-gpu opacity-50 text-${props.color} stroke-current`}  >
    <path fill="none" strokeLinecap="round" strokeWidth={props.size / 2}
      d="M 10,50
           Q 25,25 40,50
           t 30,0 30,0 30,0" />
  </svg>
)

const Landing = () => (
  <article >

    <Helmet>
      <title>Agimo.io</title>
    </Helmet>



    <section id="banner" className="relative  bg-gradient-to-br h-full w-full from-green-200 to-green-100">

      <div className="absolute bottom-1/4 top-1/4 w-1/6 h-1/6 flex flex-row justify-between transform -rotate-12">
        <Cross size={24} color={"primary"} transform="rotate-6" />
        <Squiggle size={34} color={"secondary"} transform="-rotate-12" className="fixed left-2" />
      </div>
      <div className="absolute right-1/4 lg:left-1/4  -bottom-24 w-1/4 h-1/4 transform rotate-12 justify-around">


        <Cross size={20} color={"tertiary"} transform="rotate-6" />

      </div>
<div className="container mx-auto p-8">
      <main  className=" py-12 mx-auto max-w-7xl sm:py-24 md:py-32 lg:py-40 xl:py-48 grid grid-cols-1 lg:grid-cols-2">


        <div className="sm:text-center lg:text-left mt-24 order-2">
          <h1 className="text-4xl tracking-tight font-bold text-agimo-purple sm:text-5xl md:text-6xl">
            <span className="block xl:inline">We are automating<br /></span>
            <span className="block bg-clip-text xl:inline">agile coaching!</span>
          </h1>
          <p className="text-base text-gray mt-3 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            We are building an easy way to become more agile without recruiting an army of agile coaches. Use our data driven approach to focus on what really matters.
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="w-full">
              {/* Signup form */}
              <NewsletterSignup tags={['beta']} action={"Join the waitlist"} />
            </div>

          </div>

        </div>
        <div className="h-full mx-auto order-1 lg:order-last w-1/2 lg:w-full">
          <img src={banner} alt="Automating agile coaching" />
        </div>

      </main>
</div>
    </section>



    <section id="product" className="text-gray-600 body-font">
      <div className="container py-24 mx-auto">
        <main className="mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6  lg:px-8 ">
          <div className="text-center">
            <h2 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">How it works</h2>
            <div className="max-w-4xl mx-auto">
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl lg:mx-0">We have designed the process to be as simple as possible. Just ask your team a few questions and instantly figure out what you need to do to get better at agile right away.</p>

            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10 mx-auto">

            <div className="rounded-xl p-8 bg-agimo-yellow">
              <div className="flex flex-col justify-items-center mb-4">
                <div className="flex flex-wrap  mr-4">
                  <span className="text-4xl  bg-primary text-white text-center align-middle rounded-full pt-3 w-16 h-16 mb-4">
                    <HiOutlineBeaker size={36} className="mx-auto my-auto" />
                  </span>
                </div>
                <div>
                  <h3 className="text-2xl font-bold ">Research</h3>

                </div>
              </div>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">We provide the questions; you collect the answers. Using our tailored surveys, you'll get an accurate picture of your teams’ performance.</p>
            </div>

            <div className="rounded-xl p-8 bg-agimo-yellow">
              <div className="flex flex-col justify-items-center mb-4">
                <div className="flex flex-wrap mr-4">
                  <span className="text-4xl  bg-primary text-white text-center align-middle rounded-full pt-3 w-16 h-16 mb-4">
                    <HiOutlineShieldCheck size={36} className="mx-auto my-auto" />
                  </span>
                </div>
                <div>
                  <h3 className="text-2xl font-bold">Improve</h3>

                </div>
              </div>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">You'll receive tailored recommendations for where to focus your improvement efforts. All our recommendations are based on industry standards and best practices.</p>
            </div>

            <div className="rounded-xl p-8 bg-agimo-yellow">
              <div className="flex flex-col justify-items-center mb-4">
                <div className="flex flex-wrap  mr-4">
                  <span className="text-4xl  bg-primary text-white items-center justify-center rounded-full pt-3 w-16 h-16 mb-4">
                    <HiOutlineDocumentSearch size={36} className="mx-auto my-auto" />
                  </span>
                </div>
                <div>
                  <h3 className="text-2xl font-bold">Compare</h3>
                </div>
              </div>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">Repeat the process as often as you want, comparing your current state to your past performance, as well as others in your industry. See where you really stand.</p>
            </div>
          </div>
        </main>
      </div>
    </section>
    <div id="features">
      <section className="text-gray-600 body-font   bg-gradient-to-b from-blue-50 to-transparent ">
        <div className="container py-24 mx-auto">

          <main className="mt-12 pt-12 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="">
              <h2 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">Something for everyone</h2>
              <div className="max-w-4xl">
                <p className="mt-3 mx-auto md:mr-0 text-base text-gray-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl ">Focused assessments for different roles make sure you get the information you need in a quick, simple manner. We will have tailored solutions for the following roles</p>
              </div>

            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mt-12 mx-auto">

              <div>
                <div>
                  <div className="w-48 h-48 rounded-full bg-white p-8 m-2 justify-center items-center">
                    <img src={product} alt="Product owners" className="w-32 " />
                  </div>
                  <h3 className="text-2xl mt-4">Product Owners</h3>
                  <div className="border-b-4 border-secondary h-2 w-32"></div>
                </div>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">Stakeholder and backlog management, maximizing value and driving product innovation is an important part for any enterprise. Don´t leave yours up to chance.</p>
              </div>

              <div>
                <div >
                  <div className="h-34 w-48 h-48 rounded-full bg-white p-8 m-2">
                    <img src={developer} alt="Development teams" className="h-34 mx-auto my-auto" />
                  </div>

                  <h3 className="text-2xl mt-4">Development teams</h3>
                  <div className="border-b-4 border-secondary h-2 w-32"></div>
                </div>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">Building the product requires team effort, which in turn requires support. By assessing how your developers are performing today, you can better support them in delivering the product.</p>
              </div>

              <div>
                <div>
                  <div className="h-34 w-48 h-48 rounded-full bg-white p-8 m-2">
                    <img src={scrummaster} alt="Scrum masters" className="h-34 mx-auto my-auto" />
                  </div>
                  <h3 className="text-2xl mt-4">Scrum Masters</h3>
                  <div className="border-b-4 border-secondary h-2 w-32"></div>
                </div>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">The scrum master's role is to coach the enterprise and help improve the ways of working. See how your scrum masters are really performing and support them in their professional growth.</p>
              </div>

              <div>
                <div>
                  <div className="h-34 w-48 h-48 rounded-full bg-white p-8 m-2">
                    <img src={manager} alt="Development managers" className="h-34 mx-auto my-auto" />
                  </div>
                  <h3 className="text-2xl mt-4">Development managers</h3>
                  <div className="border-b-4 border-secondary h-2 w-32"></div>
                </div>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">Understanding the daily challenges your direct reports are facing, getting support coaching them and help in integrating agile practices further into your organization.</p>
              </div>
            </div>
          </main>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container mb-24 mx-auto">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="text-center flex flex-col justify-center">
              <h2 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">Actionable insights</h2>
              <div className="max-w-4xl mx-auto">
                <p className="mt-3 mx-auto text-base text-gray-500 sm:mt-5 sm:text-lg  md:mt-5 md:text-xl lg:mx-0">After completing your assessment, you will receive a detailed report including recommended actions, deep-dive material and curated articles to follow.</p>
              </div>
              <div className="h-full w-full mx-auto bg-gray-50 rounded-2xl p-16 items-center justify-items-center">
                <img src={screenshot2} alt="Screenshot" className="rounded-2xl mx-auto " />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-10 mx-auto">

              <div className="flex flex-row">
                <div className="mr-4 text-primary">
                  <HiOutlineTrendingUp size={34} />
                </div>

                <div className="flex flex-col">
                  <h3 className="text-2xl font-bold mb-2">Quick overview</h3>

                  <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                    The results start with a quick overview section including an easy-to-read radar chart where you can quickly see what ar eas need work. The quick overview will contain all the information you need for a quick status assessment.
                  </p>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mr-4 text-primary">
                  <HiOutlineLightBulb size={34} />
                </div>

                <div className="flex flex-col">
                  <h3 className="text-2xl font-bold mb-2">Detailed answers</h3>

                  <p className=" text-base text-gray-500 sm:text-md sm:max-w-xl sm:mx-auto md:text-lg lg:mx-0">
                    Each assessment is split into different areas, each one focusing on a vital dimension of the role. Take a deep dive and discover new elements to explore in your agile ways of working.</p>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mr-4 text-primary">
                  <HiOutlineStar size={34} />
                </div>

                <div className="flex flex-col">
                  <h3 className="text-2xl font-bold mb-2">Focusing your time</h3>
                  <p className=" text-base text-gray-500 sm:text-md sm:max-w-xl sm:mx-auto md:text-lg lg:mx-0">
                    A chain is only as strong as its weakest link. We will identify what areas are underdeveloped, so that you can focus your efforts where they really matter, and you get the biggest return on the time you invest.</p>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mr-4 text-primary">
                  <HiOutlineFingerPrint size={34} />
                </div>

                <div className="flex flex-col">
                  <h3 className="text-2xl font-bold mb-2">Personalized results</h3>

                  <p className=" text-base text-gray-500 sm:text-md sm:max-w-xl sm:mx-auto md:text-lg lg:mx-0">
                    Depending on your results, we will identify specific tasks to complete. Either try and experiment, learn new things or discuss within your organization how to proceed.
                  </p>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mr-4 text-primary">
                  <HiOutlineClipboardCheck size={34} />
                </div>

                <div className="flex flex-col">
                  <h3 className="text-2xl font-bold mb-2">Your task list</h3>

                  <p className=" text-base text-gray-500 sm:text-md sm:max-w-xl sm:mx-auto md:text-lg lg:mx-0">
                    Collect all your tasks and improvement efforts across different teams and roles into a single task list for a quick overview. Continuous improvement has never been so easy.
                  </p>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="mr-4 text-primary">
                  <HiOutlineDocumentSearch size={34} />
                </div>

                <div className="flex flex-col">
                  <h3 className="text-2xl font-bold mb-2">Compare</h3>
                  <p className=" text-base text-gray-500 sm:text-md sm:max-w-xl sm:mx-auto md:text-lg lg:mx-0">
                    Compare each assessment result with your previous assessment results to discover trends, identify bottle necks and problem areas. Later on, you will also be able to compare your results anonymously with others within your or other industries.
                  </p>
                </div>
              </div>

            </div>
          </main>
        </div>
      </section>

      {/* Areas */}
      <section className="text-gray-600 body-font bg-gradient-to-b from-blue-50 to-transparent">
        <div className="container py-24 mx-auto ">
          <div className="text-center w-2/3 mx-auto mb-20 ">
            <div className="grid grid-cols-2">

              <div>
                <img src={holistic} alt="Holistic view" />
              </div>

              <div>


                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">Holistic 360° view</h1>
                <p className="mt-3 mx-auto text-base text-gray-500 sm:mt-5 sm:text-lg  md:mt-5 md:text-xl lg:mx-0">Our assessments take all aspects into consideration, as modern product development and agile initiatives are complex and require more than just one thing. We evaluate:</p>
              </div></div>
          </div>
          <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2 max-w-4xl">
            <div className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-100 flex p-4 h-full items-center">
                <CgBowl className="text-primary w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium">Team values</span>
              </div>
            </div>
            <div className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                <CgBoard className="text-primary w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium">Scrum artifacts</span>
              </div>
            </div>
            <div className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                <CgSmartphoneChip className="text-primary w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium">Development practices</span>
              </div>
            </div>
            <div className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                <CgExtension className="text-primary w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium">Environment and organization support</span>
              </div>
            </div>
            <div className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                <CgCheckO className="text-primary w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium">Quality assurance</span>
              </div>
            </div>
            <div className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                <CgBulb className="text-primary w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium">UI/UX practices</span>
              </div>
            </div>
            <div className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-50 rounded flex p-4 h-full items-center">
                <CgUserList className="text-primary w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium">... and more</span>
              </div>
            </div>
          </div>
          <div className="text-center w-2/3 mx-auto mt-20 ">
            <p className="mt-3 mx-auto text-base text-gray-500 sm:mt-5 sm:text-lg  md:mt-5 md:text-xl lg:mx-0">... everything a high performing team needs</p>
          </div>
        </div>
      </section>
    </div>

    <section className="text-gray-600 body-font">
      <div className="container py-24 mx-auto">
        <div className="rounded-2xl bg-agimo-yellow w-full grid grid-cols-1  lg:grid-cols-2">
          <div className="flex h-full items-end mx-auto">
            <img className="overflow-hidden w-1/2 lg:w-full  pt-4 mx-auto p-0 lg:pt-16 lg:pl-16 justify-end" src={marketblast} alt="Sign up" />
          </div>
          <div className="flex flex-col gap-8 pl-16 lg:pl-0 pt-16 pr-16 pb-16  justify-center">
            <h2 className="text-3xl font-bold">Still not convinced?</h2>
            <p className="text-base">Agimo will be free during our early access period. Sign up for the early access waiting list and be one of the first to join the digital Agile coaching revolution.</p>
            <NewsletterSignup tags={['beta']} action={"Get an invite"} />
          </div>

        </div>
      </div>
    </section>


    <footer id="company" className="bg-agimo-purple text-white px-6 pb-24 pt-12">
      <main className="mt-5 mx-auto max-w-7xl px-4 sm:mt-6 sm:px-6 md:mt-8 lg:mt-10 lg:px-8 xl:mt-14">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mx-auto max-w-7xl">

          <div className="col-span-1 md:col-span-2 lg:col-span-3">
            <div className="sm:text-center lg:text-left ">
              <h2 className="text-primary ext-2xl tracking-tight font-extrabold sm:text-xl md:text-2xl">About</h2>
              <p className="mt-3 text-base sm:mt-5 sm:mx-auto md:mt-5 md:text-lg lg:mx-0">Agimo is a new digital service being developed by the boutique management consulting company CoreMotif. CoreMotif has operations in Stockholm and Reykjavík, where we have helped large and small businesses improve their Agile Ways of Working, strategies, processes and operations. Agimo is the product of boiling all our knowledge and experience into a functional framework, and now being made accessible to you.</p>
              <p className="mt-3 text-base sm:mt-5 sm:mx-auto md:mt-5 md:text-lg lg:mx-0">Copyright © 2020 All Rights Reserved by Agimo, a <a href="https://www.coremotif.com">CoreMotif AB </a>initiative</p>
              <p className="mt-3 text-base sm:mt-5 sm:mx-auto md:mt-5 md:text-lg lg:mx-0">Illustration by <a href="https://icons8.com/illustrations/author/6101992cfc3ba40007aa1554">Fruzka</a> from <a href="https://icons8.com/illustrations">Ouch!</a></p>
            </div>
          </div>

          <div className="col-span-1">
            <div className="sm:text-center lg:text-left mx-auto max-w-7xl ">
              <h2 className="text-2xl tracking-tight font-extrabold sm:text-xl md:text-2xl text-gray-200">Useful Links</h2>
              <ul className="mt-3 text-base sm:mt-5 sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                <li className="hover:underline"><a href="mailto:info@agimo.io">info@agimo.io</a></li>
                <li className="hover:underline"><a href="https://www.coremotif.com" target="_blank" rel="noreferrer">CoreMotif</a></li>
                <li className="hover:underline"><Link to={ROUTES.TERMS_OF_SERVICE}>Terms and Conditions</Link></li>
                <li className="hover:underline"><Link to={ROUTES.PRIVACY_POLICY}>Privacy policy</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </footer>
  </article>

)
export default Landing;