import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import AnalyticsComponent from 'components/analytics/AnalyticsComponent';

import 'index.css';

import App from 'App';
import Firebase, { FirebaseContext } from 'components/Firebase';
import { ModalProvider } from 'components/Modal';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <FirebaseContext.Provider value={new Firebase()}>
        <AnalyticsComponent />
        <ModalProvider>
          <App />
        </ModalProvider>
      </FirebaseContext.Provider>
    </Router>
  </React.StrictMode>
  , document.getElementById('root')
);