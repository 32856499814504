import React from 'react';
 
import { withFirebase } from 'components/Firebase';
 
const SignOutButton = ({ firebase }) => (
  <button type="button" className="btn btn-secondary" onClick={firebase.doSignOut}>
    Sign Out
  </button>
);
 
export default withFirebase(SignOutButton);