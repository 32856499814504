import React from 'react';
import { ModalContext } from './ModalProvider';

const useModal = () => {
    const context = React.useContext(ModalContext)
    
    if (context === undefined) {
      throw new Error('useModal must be used within a ModalProvider')
    }
  
    return context
  }

  export default useModal;