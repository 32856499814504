import React, { useEffect, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { withFirebase } from 'components/Firebase';

import { HiCheckCircle, HiXCircle, HiOutlineThumbUp } from 'react-icons/hi';

const url = 'https://agimo.us7.list-manage.com/subscribe/post?u=5c65db1f401039161978a0bb9&amp;id=51f8b92111'


const CustomFormBase = (props) => {

    let email = { value: ''};
    const { status, message, onValidated, action } = props;
    const analytics = props.firebase.analytics;
    
    const [isDisabled, setIsDisabled] = useState(false);
    /**
     * Monitor the value of the status message, if successful create the beta key and log in analytics
     */
    useEffect(() => {
        if (status === 'success') {

            setIsDisabled(true);
            const timestamp = props.firebase.getTimestamp();
            analytics.logEvent('goal_completion', { name: 'newsletter_signup' });
            props.firebase.betaSignups().add({
                'email': email?.value,
                'created': timestamp,
                'used': false,
                'active': false
            });

            // Enable the button again after 5 seconds
            setTimeout(() => { setIsDisabled(false); }, 5000);

        }
    }, [status, analytics, email.value, props.firebase]);


    /**
     * Handle the form submission
     */
    const submit = () => {
        email &&
            email.value.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email.value
            })
    }

    return (

        <div>
            <div className="w-full flex flex-row bg-white p-2 rounded-2xl border-gray gap-2">


                <input
                    type="email"
                    ref={node => (email = node)}
                    className="w-full input rounded-xl"
                    placeholder="Your email"
                />

                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={submit}
                    disabled={isDisabled}
                >
                    {action ? action : 'Sign up'}
                </button>
            </div>

            <div>
                {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                {status === "error" && (


                    <ul className="text-base mt-4">
                        <li className="flex flex-row gap-2 items-center">
                            <HiXCircle className="text-primary" size={18} />
                            <span className="text-gray">
                                <div
                                    className="text-secondary"
                                    dangerouslySetInnerHTML={{ __html: message }}
                                />
                            </span>
                        </li>

                    </ul>
                )}
                {status === "success" && (
                    <div>


                        <ul className="text-base mt-4">
                            <li className="flex flex-row gap-2 items-center">
                                <HiOutlineThumbUp className="text-primary" size={18} />
                                <span className="text-gray">
                                    <div
                                        style={{ color: "green" }}
                                        dangerouslySetInnerHTML={{ __html: message }}
                                    />
                                </span>
                            </li>
                            <li className="flex flex-row gap-2 items-center">
                                <HiOutlineThumbUp className="text-primary " size={18} />
                                <span className="text-gray">
                                    Check your email! You're subscribed to the list
                                </span>
                            </li>
                            <li className="flex flex-row gap-2 items-center">
                                <HiOutlineThumbUp className="text-primary" size={18} />
                                <span className="text-gray">
                                    Hang tight, the invite will arrive shortly
                                </span>

                            </li>

                        </ul>
                    </div>
                )}
            </div>

            {!status && (
                <ul className="text-base mt-4">
                    <li className="flex flex-row gap-2 items-center">
                        <HiCheckCircle className="text-primary " size={18} />
                        <span className="text-gray">
                            No spam messages
                        </span>
                    </li>
                    <li className="flex flex-row gap-2 items-center">
                        <HiCheckCircle className="text-primary" size={18} />
                        <span className="text-gray">
                            We will update you on early access only
                        </span>

                    </li>
                    <li className="flex flex-row gap-2 items-center">
                        <HiCheckCircle className="text-primary" size={18} />
                        <span className="text-gray">
                            Unsubscribe whenever you want
                        </span>
                    </li>
                </ul>
            )
            }
        </div >

    )
}

const CustomForm = withFirebase(CustomFormBase)

export const NewsletterSignup = (props) => {

    const { action } = props;

    return (
        <div className="mc_form-container">
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        action={action}
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    )
}