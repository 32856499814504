import React, { Component } from "react";
import * as ROUTES from 'constants/routes';
import { Link } from 'react-router-dom';

import Logo from 'components/App/logo';

class AuthLayout extends Component {
    render() {
        return (
            <div>
                <main>
                    <div className="bg-white w-full h-screen">

                        <div className="flex h-screen flex-col md:flex-row">

                            {/* The image section (left) */}
                            <div className="bg-agimo-purple ">
                                <div className="w-full flex flex-row md:flex-col ">


                                    <div className="p-4 sm:p-12">
                                        <Link to={ROUTES.LANDING}>
                                            <Logo />
                                        </Link>
                                    </div>


                                    <h1 className="p-4 sm:p-12 tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl xl:text-6xl">
                                        <span className="block xl:inline text-white">
                                            Take the <br />
                                            <span>guess work </span><br />
                                        </span>
                                        <span className="block bg-clip-text text-secondary">
                                            out of agile
                                            </span>
                                    </h1>
                                </div>
                            </div>

                            {/* The form section (right) */}
                            <div className="w-full flex flex-col">
                                <div className="max-w-xl w-full mx-auto my-auto">

                                    <div className="flex flex-col justify-center md:justify-start my-auto p-16 bg-white rounded-2xl xl:bg-agimo-yellow" >
                                        {/* Here is where we stick the form */}
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default AuthLayout;