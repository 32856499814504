import React, { useCallback, useState } from 'react';
import Modal from './Modal';

export const ModalContext = React.createContext();

const ModalProvider = props => {
    
    const [modal, setModal] = useState();
    
    const unSetModal = useCallback( ()=>{
        setModal();
    },[setModal]);

    return (
        <ModalContext.Provider value={{ unSetModal, setModal }} {...props} >
          {props.children}
          {modal && <Modal modal={modal} unSetModal={unSetModal}  />}
        </ModalContext.Provider>
      )
}

export default ModalProvider;