import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HiOutlineX } from 'react-icons/hi';

const Modal = props => {

  // De-structure the things from the things ... 
  const { modal, unSetModal } = props;
  
  useEffect(() => {

    const bind = e => {
      if (e.keyCode !== 27) {
        return
      }

      if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) {
        return
      }

      unSetModal()
    }

    document.addEventListener('keyup', bind)
    return () => document.removeEventListener('keyup', bind)
  }, [modal, unSetModal, props])


  // We create a brand spanking new portal next to the document root, using the modal root.
  return ReactDOM.createPortal(
    
      <div className="fixed top-0 h-screen w-screen flex items-center justify-center bg-gray-700 bg-opacity-50 z-20">
        <div className={"card w-11/12 md:w-3/4 lg:w-3/5 xl:w-1/2 md:max-w-4xl bg-white lg:card-side border border-gray-300 z-40"}  onClick={e => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}>
          <div className="absolute top-0 right-0 mt-8 mr-8">
            <div className="absolute top-0 right-0">
              <button className="border-transparent border-0" onClick={unSetModal}>
                <HiOutlineX className="fill-current text-gray-400" size="24" />
              </button>
            </div>
          </div>

          <div className="card-body w-full">
            {modal}
          </div>
        </div>
      </div>
   
    ,  document.getElementById('modal'))
}

export default Modal;