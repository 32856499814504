import React, { useContext } from "react";
import { Sidebar } from 'components/Navigation';
import { withAuthorization, EmailVerification } from 'components/Session';
import { AuthUserContext } from 'components/Session';

const AppLayout = (props) => {

    const authUser = useContext(AuthUserContext);

    return (
        <div className="flex flex-col sticky min-h-full h-full lg:flex-row">
            <aside className="transition-all fixed flex flex-row lg:flex-col justify-between w-full lg:w-28 xl:w-80 h-24  lg:bg-default lg:sticky lg:h-screen lg:top-0 z-50 bg-gradient-to-t  from-transparent via-white to-white p-4 overflow-hidden">
                <Sidebar user={authUser} />
            </aside>

            <main className="block lg:block h-full mt-16 lg:mt-0 w-full p-5 flex-grow bg-default max-w-screen-xl text-content-900 ">
                <EmailVerification user={authUser} />
                {props.children}
            </main>
        </div>
    );
}

// Only allow authenticated users
const condition = authUser => !!authUser;
export default withAuthorization(condition)(AppLayout);